import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const ContactUsForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_AEjQ3NbqxX5ZpA1y1z3Nc3pI', null, captchaRef)}
      id="contact-us-form"
    >
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="firstName"
        name="firstName"
        value=""
        type="text"
        required
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="lastName"
        name="lastName"
        value=""
        type="text"
        required
      />
      <h3>Email address *</h3>
      <input
        className="form-control"
        id="email"
        name="email"
        value=""
        type="email"
        required
      />
      <h3>Message *</h3>
      <textarea
        id="message"
        rows="9"
        cols="33"
        name="message"
        required
      />
      <ReCAPTCHA
        ref={captchaRef}
        classNameName="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

ContactUsForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

ContactUsForm.defaultProps = {
  buttonText: null,
};
