/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Checkbox } from '@powdr/components';
import { ButtonText } from '@powdr/constants';

export const SignupSuccessForm = ({
  reCaptchaPublicKey,
  // processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();
  return (
    <form
      id="signup-success"
      action="https://www.pages03.net/powdr/SunCountryToursSignUp/SunCountryOptInPage"
      method="post"
      pageid="13538740"
      parentpageid="13538738"
      siteid="518538"
    >
      <input name="formSourceName" type="hidden" value="StandardForm" />
      <input name="sp_exp" type="hidden" value="yes" />

      <h3>Email address *</h3>
      <input
        name="email"
        id="control_EMAIL"
        className="form-control"
        type="email"
        required
      />

      <h3>First Name *</h3>
      <input
        name="First Name"
        id="control_COLUMN2"
        className="form-control"
        type="text"
        required
      />

      <h3>Last Name *</h3>
      <input
        name="Last Name"
        id="control_COLUMN3"
        className="form-control"
        type="text"
        required
      />

      <h3>Postal Code *</h3>
      <input
        name="Postal Code"
        id="control_COLUMN7"
        className="form-control"
        type="text"
        required
      />

      <Checkbox
        isIgnoreLabelFor
        className="formCheckbox"
        roundedToggle
        name="SUBSCRIPTION_GROUP"
        value="8071039"
        label="Sun Country Tours Rafting"
      />
      <Checkbox
        isIgnoreLabelFor
        className="formCheckbox"
        roundedToggle
        name="SUBSCRIPTION_GROUP"
        value="6033655"
        label="Family Activities"
      />
      <Checkbox
        isIgnoreLabelFor
        className="formCheckbox"
        roundedToggle
        name="SUBSCRIPTION_GROUP"
        value="5820549"
        label="Summer News and Events from Mt. Bachelor"
      />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

SignupSuccessForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  // processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

SignupSuccessForm.defaultProps = {
  buttonText: null,
};
