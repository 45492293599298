import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#F7F6F2',
    [Prop.HEADER]: '#0087B8',
    [Prop.CONTENT]: '#003852',
    [Prop.LINK_TXT]: '#FFC91F',
    [Prop.LINK_TXT_HOVER]: '#000000',
    [Prop.BTN_BG]: '#003852',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#FFC91F',
    [Prop.BTN_TXT_HOVER]: '#000000',
    [Prop.BTN_BG_ACTIVE]: '#FFC91F',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#C7C8CA',
    [Prop.BTN_TXT_INACTIVE]: '#000000',
    [Prop.ELEMENT]: '#596B5E',
    [Prop.ELEMENT_TXT]: '#F7F6F2',
    [Prop.BORDER]: '#C7C8CA',
    [Prop.ICON]: '#0087B8',
    [Prop.ICON_HOVER]: '#FFC91F',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#003852',
    [Prop.HEADER]: '#FFC91F',
    [Prop.CONTENT]: '#F7F6F2',
    [Prop.LINK_TXT]: '#FFC91F',
    [Prop.LINK_TXT_HOVER]: '#F7F6F2',
    [Prop.BTN_BG]: '#FFC91F',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#17A3DE',
    [Prop.BTN_TXT_HOVER]: '#000000',
    [Prop.BTN_BG_ACTIVE]: '#17A3DE',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#C7C8CA',
    [Prop.BTN_TXT_INACTIVE]: '#000000',
    [Prop.ELEMENT]: '#F7F6F2',
    [Prop.ELEMENT_TXT]: '#003852',
    [Prop.BORDER]: '#C7C8CA',
    [Prop.ICON]: '#FFC91F',
    [Prop.ICON_HOVER]: '#17A3DE',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#17A3DE',
    [Prop.HEADER]: '#003852',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#F7F6F2',
    [Prop.LINK_TXT_HOVER]: '#003852',
    [Prop.BTN_BG]: '#003852',
    [Prop.BTN_TXT]: '#F7F6F2',
    [Prop.BTN_BG_HOVER]: '#F7F6F2',
    [Prop.BTN_TXT_HOVER]: '#000000',
    [Prop.BTN_BG_ACTIVE]: '#F7F6F2',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#000000',
    [Prop.BTN_TXT_INACTIVE]: '#F7F6F2',
    [Prop.ELEMENT]: '#6B0D14',
    [Prop.ELEMENT_TXT]: '#F7F6F2',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#003852',
    [Prop.ICON_HOVER]: '#F7F6F2',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#FFC91F',
    [Prop.HEADER]: '#003852',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#F7F6F2',
    [Prop.LINK_TXT_HOVER]: '#003852',
    [Prop.BTN_BG]: '#003852',
    [Prop.BTN_TXT]: '#F7F6F2',
    [Prop.BTN_BG_HOVER]: '#F7F6F2',
    [Prop.BTN_TXT_HOVER]: '#000000',
    [Prop.BTN_BG_ACTIVE]: '#F7F6F2',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#000000',
    [Prop.BTN_TXT_INACTIVE]: '#F7F6F2',
    [Prop.ELEMENT]: '#6B0D14',
    [Prop.ELEMENT_TXT]: '#FFC91F',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#003852',
    [Prop.ICON_HOVER]: '#F7F6F2',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#F7F6F2',
    [Prop.CONTENT]: '#F7F6F2',
    [Prop.LINK_TXT]: '#FFC91F',
    [Prop.LINK_TXT_HOVER]: '#17A3DE',
    [Prop.BTN_BG]: '#003852',
    [Prop.BTN_TXT]: '#F7F6F2',
    [Prop.BTN_BG_HOVER]: '#F7F6F2',
    [Prop.BTN_TXT_HOVER]: '#003852',
    [Prop.BTN_BG_ACTIVE]: '#17A3DE',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#C7C8CA',
    [Prop.BTN_TXT_INACTIVE]: '#000000',
    [Prop.ELEMENT]: '#F7F6F2',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#C7C8CA',
    [Prop.ICON]: '#FFC91F',
    [Prop.ICON_HOVER]: '#17A3DE',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#F7F6F2',
    [Prop.NAV_BG]: '#F7F6F2',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#003852',
    [Prop.NAV_ITEM_TXT_SOLID]: '#003852',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#003852',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#F7F6F2',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#FFC91F',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
  [Components.STICKY_NAV]: {
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
  },
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#F7F6F2',
    [Prop.MENU_BTN_ICON]: '#0087B8',
    [Prop.LOGO_BG]: '#003852',
    [Prop.CLOSE_BTN_BG]: '#FFC91F',
    [Prop.CLOSE_BTN_ICON]: '#000000',
    [Prop.MAIN_NAV_BG]: '#003852',
    [Prop.MAIN_NAV_TXT]: '#F7F6F2',
    [Prop.MAIN_NAV_BORDER]: '#F7F6F2',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#F7F6F2',
    [Prop.DRAWER_BTN_TXT]: '#003852',
    [Prop.PROMO_BTN_BG]: '#F7F6F2',
    [Prop.PROMO_BTN_TXT]: '#003852',
    [Prop.SUB_NAV_BG]: '#FFC91F',
    [Prop.SUB_NAV_TXT]: '#000000',
    [Prop.SUB_NAV_BORDER]: '#F7F6F2',
  },
};
